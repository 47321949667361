import React from "react";
import "./responseMessage.css";

export default function ResponseMessage({ message, active, messageType }) {
  if (active) {
    return (
      <div className={"responseMessage" + ` ${messageType}`}>{message}</div>
    );
  } else {
    return null;
  }
}
