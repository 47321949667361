import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../components/form/form.css";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import signUpUser from "../../helpers/auth/signUpUser";

export default function SignUp() {
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    discordUsername: "",
    passwordOne: "",
    passwordTwo: "",
    responseMessage: "",
    responseMessageActive: false,
    responseMessageType: null,
    loading: false,
  });

  const afterSignUp = (responseMessageType, responseMessage) => {
    if (responseMessageType !== "error") {
      history.push("/PreConnectContract");
      setFormData({ ...formData, loading: false });
    } else {
      setFormData({
        ...formData,
        loading: false,
        responseMessageActive: true,
        responseMessageType: responseMessageType,
        responseMessage: responseMessage,
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />
        <FormInput
          placeHolder={"Discord username"}
          value={formData.discordUsername}
          setStateValue={(discordUsername) =>
            setFormData({ ...formData, discordUsername: discordUsername })
          }
        />
        <FormInput
          type="password"
          placeHolder={"Password"}
          value={formData.passwordOne}
          setStateValue={(password) =>
            setFormData({ ...formData, passwordOne: password })
          }
        />
        <FormInput
          type="password"
          placeHolder={"Confirm Password"}
          value={formData.passwordTwo}
          setStateValue={(password) =>
            setFormData({ ...formData, passwordTwo: password })
          }
        />
        <FormButton
          label="Sign up"
          func={signUpUser}
          funcParams={[
            formData.email,
            formData.discordUsername,
            formData.passwordOne,
            formData.passwordTwo,
          ]}
          afterCallFunc={afterSignUp}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={false}
        />

        <ResponseMessage
          message={formData.responseMessage}
          active={true}
          messageType={formData.responseMessageType}
        />
      </div>
    </div>
  );
}
