import React from "react";
import { IoChevronDownCircle, IoChevronUpCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import "./chevronCircle.css";

export default function ChevronCircle({
  section,
  configOpenToggleAction,
  configOpenToggleActionParams,
  configOpen,
}) {
  const dispatch = useDispatch();

  return (
    <>
      {configOpen ? (
        <IoChevronDownCircle
          className="chevronCircle"
          onClick={() =>
            dispatch(configOpenToggleAction(configOpenToggleActionParams))
          }
        />
      ) : (
        <IoChevronUpCircle
          className="chevronCircle"
          onClick={() =>
            dispatch(configOpenToggleAction(configOpenToggleActionParams))
          }
        />
      )}
    </>
  );
}
