import React from "react";
import { v4 as uuidv4 } from "uuid";
import { addItemDynamicContractDataValue } from "../../../../../../../redux/dapp";
import ConfigContractFuncButton from "../configContractFuncButton/ConfigContractFuncButton";
import ConfigContractFuncsContainer from "../ConfigContractFuncsContainer";
import "./configContractViewFuncs.css";

export default function ConfigContractViewFuncs({
  block,
  section,
  item,
  contract,
}) {
  const displayContractViewFuncs = contract.abi.map((func, idx) => {
    if (
      (func.type === "function") &
      (func.stateMutability === "view") &
      (func.inputs < 1)
    ) {
      return (
        <ConfigContractFuncButton
          key={idx}
          section={section}
          block={block}
          item={item}
          func={func}
          onClickFunc={addItemDynamicContractDataValue}
          onClickFuncParams={{
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            textValue: "",
            value: {
              id: uuidv4(),
              type: "viewFunc",
              textValue: "",
              viewFunc: { ...func, contract: contract },
            },
          }}
        />
      );
    }
  });

  return <ConfigContractFuncsContainer funcs={displayContractViewFuncs} />;
}
