import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from "../../../firebase/firebase";
import { useSelector, useDispatch } from "react-redux";
import { addBlock, toggleWindowClick } from "../../../redux/dapp";
import { populateDapp } from "../../../redux/dapp";
import DbSidebar from "../../../components/dappBuilder/dbContainer/dbSidebar/DbSidebar";
import DbRightArea from "../../../components/dappBuilder/dbRightAreaContainer/DbRightAreaContainer";
import "./dappBuilder.css";
import DbTopBar from "../../../components/dappBuilder/dbTopBar/DbTopBar";
import DbContainer from "../../../components/dappBuilder/dbContainer/DbContainer";

export default function DappBuilder() {
  const db = firebase.firestore();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const docRef = db
        .collection("users")
        .doc(user.uid)
        .collection("dapps")
        .doc(location.state.dapp.id);

      const result = await docRef.get();
      dispatch(populateDapp(result.data()));
    });
  }, []);

  const initiateWindowClick = (e) => {
    if (
      e.target.className === "colorPicker-squareButton" ||
      e.target.className === "configStandardButton" ||
      e.target.className === "custom-file-input" ||
      e.target.className === "config-fontPickerButton"
    ) {
      return;
    }
    dispatch(toggleWindowClick());
  };

  return (
    <div className="dappBuilder" onClick={(e) => initiateWindowClick(e)}>
      <DbTopBar />
      <DbContainer />
    </div>
  );
}
