import React from "react";
import DbCanvas from "./dbCanvas/DbCanvas";
import "./dbContainer.css";
import DbSidebar from "./dbSidebar/DbSidebar";

export default function DbContainer() {
  return (
    <div className="dbContainer">
      <DbSidebar />
      <DbCanvas inBuilder={true} />
    </div>
  );
}
