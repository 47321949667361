import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../spinnerComponent/SpinnerComponent";
import "./dbCanvas.css";
import Section from "./section/Section";

export default function DbCanvas({ inBuilder }) {
  const reduxData = useSelector((state) => state.dapp);

  const displaySections = reduxData.dapp.frontendStructure.sections.map(
    (section, idx) => {
      return <Section key={idx} section={section} inBuilder={inBuilder} />;
    }
  );

  return (
    <>
      {reduxData.loadingDapp ? (
        <div className="dbCanvas-loading">
          <Spinner />
        </div>
      ) : (
        <div className="dbCanvas">{displaySections}</div>
      )}
    </>
  );
}
