import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";
import "./configContractFuncsContainer.css";

export default function ConfigContractFuncsContainer({ funcs }) {
  const [showingFunctions, setShowingFunctions] = useState(false);
  const reduxData = useSelector((state) => state.dapp);

  useEffect(() => {
    setShowingFunctions(false);
  }, [reduxData.dapp.config.windowClick]);

  return (
    <div>
      <ConfigStandardButton
        label="Functions"
        func={setShowingFunctions}
        funcParams={!showingFunctions}
      />
      {showingFunctions && (
        <div className="configContractFuncsContainer">{funcs}</div>
      )}
    </div>
  );
}
