import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getImages from "../../../../../../helpers/getImages";
import saveImages from "../../../../../../helpers/saveImages";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";
import FileInput from "../fileInput/FileInput";
import "./imagePicker.css";
import ImagePickerImage from "./imagePickerImage/ImagePickerImage";

export default function ImagePicker({ section, block, item }) {
  const reduxData = useSelector((state) => state.dapp);

  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [imagesUrls, setImagesUrls] = useState([]);

  useEffect(() => {
    setImagePickerOpen(false);
  }, [reduxData.dapp.config.windowClick]);

  useEffect(() => {
    initiateGetImages();
  }, []);

  const afterGetImages = (urls) => {
    setImagesUrls(urls);
  };

  const initiateGetImages = async () => {
    getImages(reduxData.dapp.images, afterGetImages);
  };

  const imagesUploaded = (newImagesIds) => {
    setUploadingImages(false);
    getImages(reduxData.dapp.images.concat(newImagesIds), afterGetImages);
  };

  const initiateSaveImages = async (files) => {
    setUploadingImages(true);
    const result = await saveImages(
      reduxData.dapp,
      reduxData.dapp.id,
      files,
      imagesUploaded
    );
  };

  const displayImages = imagesUrls.map((imageUrl, idx) => {
    return (
      <ImagePickerImage
        key={idx}
        section={section}
        block={block}
        item={item}
        imageUrl={imageUrl}
      />
    );
  });

  return (
    <div className="imagePicker">
      <ConfigStandardButton
        label="Images"
        func={setImagePickerOpen}
        reduxFunc={false}
        funcParams={!imagePickerOpen}
      />
      {imagePickerOpen && (
        <div className="imagePicker-container">
          <FileInput
            onChangeFunc={initiateSaveImages}
            loading={uploadingImages}
          />
          <div className="imagePicker-images">{displayImages}</div>
        </div>
      )}
    </div>
  );
}
