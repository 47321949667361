import React from "react";
import "./configInput.css";
import { useDispatch } from "react-redux";

export default function ConfigInput({
  label,
  type,
  value,
  onChangeFunc,
  onChangeFuncParams,
}) {
  const dispatch = useDispatch();

  return (
    <div className="configInput">
      <label>{label}</label>
      <input
        className="configInput-input"
        type={type}
        value={value}
        onChange={(e) => {
          onChangeFuncParams.textValue = e.target.value;
          dispatch(onChangeFunc(onChangeFuncParams));
        }}
      />
    </div>
  );
}
