import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import SuccessMessage from "../../components/form/successMessage/SuccessMessage";
import resetPassword from "../../helpers/auth/resetPassword";

export default function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: "",
    responseMessage: "",
    responseMessageActive: false,
    responseMessageType: null,
    loading: false,
  });

  const initiateResetPassword = () => {
    resetPassword(formData.email);
  };

  const afterFuncCall = (responseMessage, responseMessageType) => {
    setFormData({
      ...formData,
      loading: false,
      email: "",
      responseMessageActive: true,
      responseMessageType: responseMessageType,
      responseMessage: responseMessage,
    });
  };

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />

        <FormButton
          label="Reset Password"
          func={resetPassword}
          funcParams={[formData.email]}
          afterCallFunc={afterFuncCall}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={!formData.email}
        />

        <ResponseMessage
          message={formData.responseMessage}
          active={true}
          messageType={formData.responseMessageType}
        />
      </div>
    </div>
  );
}
