import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import FormTextArea from "../../components/form/formTextArea/FormTextArea";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";
import handleConnectContract from "../../helpers/handleConnectContract.js";

export default function ConnectContract() {
  const initialFrontendStructure = {
    sections: [
      {
        id: uuidv4(),
        backgroundColor: "#f0f0f0",
        config: {
          configOpen: true,
          configActive: true,
          selectedBlockConfig: "",
        },
        blocks: [],
      },
    ],
    font: {
      fontFamily: "Roboto",
      config: { configOpen: true, configActive: true },
    },
    connectButton: { backgroundColor: "", textColor: "" },
  };

  const history = useHistory();
  const location = useLocation();

  const [formData, setFormData] = useState({
    dappId: uuidv4(),
    contractAddress: "",
    contractAbi: [],
    dappName: "",
    changingContract: false,
    buttonDisabled: true,
    responseMessage: "",
    responseMessageActive: false,
    responseMessageType: null,
    loading: false,
  });

  useEffect(() => {
    if (location.state) {
      setFormData({
        ...formData,
        changingContract: true,
        dappId: location.state.dapp.id,
        buttonDisabled: false,
      });
    } else {
      setFormData({
        ...formData,
        buttonDisabled: false,
      });
    }
  }, []);

  const afterCallFunc = (responseMessageType, responseMessage) => {
    if (responseMessageType !== "error") {
      history.push("/DappsDashboard");
      setFormData({ ...formData, loading: false });
    } else {
      setFormData({
        ...formData,
        loading: false,
        responseMessageActive: true,
        responseMessageType: responseMessageType,
        responseMessage: responseMessage,
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          placeHolder={"Contract address"}
          value={formData.contractAddress}
          setStateValue={(contractAddress) =>
            setFormData({ ...formData, contractAddress: contractAddress })
          }
        />

        <FormTextArea
          placeHolder={"Contract abi"}
          value={formData.contractAbi}
          setStateValue={(contractAbi) =>
            setFormData({ ...formData, contractAbi: contractAbi })
          }
        />

        <FormInput
          placeHolder={"Dapp name"}
          value={formData.dappName}
          setStateValue={(dappName) =>
            setFormData({ ...formData, dappName: dappName })
          }
        />

        <FormButton
          label={
            formData.changingContract ? "Change Contract" : "Connect Contract"
          }
          func={handleConnectContract}
          funcParams={[
            formData.dappId,
            formData.dappName.replace(/\s/g, ""),
            initialFrontendStructure,
            formData.contractAddress,
            formData.contractAbi,
            false,
            formData.changingContract,
            afterCallFunc,
          ]}
          afterCallFunc={afterCallFunc}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={formData.buttonDisabled}
        />

        <ResponseMessage
          message={formData.responseMessage}
          active={formData.responseMessageActive}
          messageType={formData.responseMessageType}
        />
      </div>
    </div>
  );
}
