import React from "react";
import "./fileInput.css";
import Spinner from "react-bootstrap/Spinner";

export default function FileInput({ onChangeFunc, loading }) {
  return (
    <>
      {!loading ? (
        <input
          className="custom-file-input"
          type="file"
          multiple
          onChange={(e) => {
            onChangeFunc(e.target.files);
          }}
        />
      ) : (
        <button>
          <Spinner animation="border" />
        </button>
      )}
    </>
  );
}
