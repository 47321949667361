import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../components/form/form.css";
import FormButton from "../../components/form/formButton/FormButton";
import FormInput from "../../components/form/formInput/FormInput";
import loginUser from "../../helpers/auth/loginUser";
import RedirectText from "../../components/form/redirectText/RedirectText";
import ResponseMessage from "../../components/form/responseMessage/ResponseMessage";

export default function Login() {
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    responseMessage: "",
    responseMessageActive: false,
    responseMessageType: null,
    loading: false,
  });

  const afterFunc = (
    responseMessageType,
    responseMessage,
    querySnapshotEmpty
  ) => {
    if (responseMessageType !== "error") {
      if (!querySnapshotEmpty) {
        history.push("/DappsDashboard");
      } else {
        history.push("/PreConnectContract");
      }
      setFormData({ ...formData, loading: false });
    } else {
      setFormData({
        ...formData,
        loading: false,
        responseMessageActive: true,
        responseMessageType: responseMessageType,
        responseMessage: responseMessage,
      });
    }
  };

  return (
    <div className="form-container">
      <div className="form">
        <FormInput
          type="email"
          placeHolder={"Email"}
          value={formData.email}
          setStateValue={(email) => setFormData({ ...formData, email: email })}
        />
        <FormInput
          type="password"
          placeHolder={"Password"}
          value={formData.passwordOne}
          setStateValue={(password) =>
            setFormData({ ...formData, password: password })
          }
        />
        <FormButton
          label="login"
          func={loginUser}
          funcParams={[formData.email, formData.password]}
          afterCallFunc={afterFunc}
          setStateLoading={(value) =>
            setFormData({ ...formData, loading: value })
          }
          loading={formData.loading}
          disabled={false}
        />

        <RedirectText
          text={"Forgot password?"}
          redirectLink={"/ForgotPassword"}
        />

        <ResponseMessage
          message={formData.responseMessage}
          active={true}
          messageType={formData.responseMessageType}
        />
      </div>
    </div>
  );
}
