import React from "react";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import ConfigInput from "../../../../configInput/ConfigInput";
import {
  changeItemTextColor,
  changeItemText,
  deleteItem,
  changeItemFontFamily,
} from "../../../../../../../../../redux/dapp";
import ConfigDeleteButton from "../../../../configDeleteButton/ConfigDeleteButton";
import ConfigFontPicker from "../../../../configFontPicker/ConfigFontPicker";

export default function TextItemConfig({ block, section, item }) {
  return (
    <div>
      <ColorPicker
        colorLabel="Text Color"
        color={item.textColor}
        changeColorFunc={changeItemTextColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
      />
      <ConfigInput
        label={"Text label"}
        type="text"
        value={item.text}
        onChangeFunc={changeItemText}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />
      <ConfigFontPicker
        item={item}
        changeFontFamilyFunc={changeItemFontFamily}
        changeFontFamilyFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
      <ConfigDeleteButton
        actionFunc={deleteItem}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
    </div>
  );
}
