import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";
import "./configInputTextCreator.css";

export default function ConfigInputTextCreator({
  section,
  block,
  item,
  actionFunc,
}) {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  return (
    <div className="configInputTextCreator">
      <input
        value={inputValue}
        className="configInputTextCreator-input"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <ConfigStandardButton
        label="Add Text"
        func={actionFunc}
        funcParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          value: { id: uuidv4(), type: "text", textValue: inputValue },
        }}
        reduxFunc={true}
        clearInputFunc={() => setInputValue("")}
      />
    </div>
  );
}
