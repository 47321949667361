import React from "react";
import { deleteItem } from "../../../../../../../../../redux/dapp";
import ConfigDeleteButton from "../../../../configDeleteButton/ConfigDeleteButton";
import ImagePicker from "../../../../imagePicker/ImagePicker";

export default function ImageItemConfig({ section, block, item }) {
  return (
    <div>
      <ImagePicker section={section} block={block} item={item} />
      <ConfigDeleteButton
        actionFunc={deleteItem}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
    </div>
  );
}
