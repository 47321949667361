import React from "react";
import { changeItemSelectedFuncPrice } from "../../../../../../redux/dapp";
import ConfigInput from "../configInput/ConfigInput";
import "./configItemFuncPrice.css";

export default function ConfigItemFuncPrice({ block, section, item }) {
  return (
    <div className="configItemFuncPrice">
      ConfigItemFuncPrice
      <ConfigInput
        label="Function Price in Eth"
        type="text"
        value={item.selectedFunc.price}
        onChangeFunc={changeItemSelectedFuncPrice}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />
    </div>
  );
}
