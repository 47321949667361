import React from "react";
import { useDispatch } from "react-redux";
import { changeItemImage } from "../../../../../../../redux/dapp";
import "./imagePickerImage.css";

export default function ImagePickerImage({ section, block, item, imageUrl }) {
  const dispatch = useDispatch();

  return (
    <img
      className="imagePickerImage"
      src={imageUrl}
      onClick={() =>
        dispatch(
          changeItemImage({
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            image: imageUrl,
          })
        )
      }
    />
  );
}
