import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addBlockItem } from "../../../../../../../../redux/dapp";

export default function BlockItemPicker({ section, block, closeItemPicker }) {
  const reduxData = useSelector((state) => state.dapp);

  const itemList = [
    "Button",
    "Connect Button",
    "Large Text",
    "Small Text",
    "Image",
    "Dynamic Contract Data",
    "Social Media Icons",
  ];

  const dispatch = useDispatch();

  const initiateAddBlockItem = (item) => {
    var selectedItem;
    if (item === "Button") {
      selectedItem = {
        id: uuidv4(),
        type: "button",
        name: "Button",
        buttonLabel: "Button",
        buttonBackgroundColor: "",
        buttonTextColor: "",
        selectedFunc: {
          name: "Select Function",
          stateMutability: "nonpayable",
          type: "function",
          inputs: [],
          outputs: [],
          contract: reduxData.dapp.contracts[0],
        },

        successMessage: "",
        errorMessage: "",
        config: {
          switchValueOne: false,
          switchValueTwo: false,
        },
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Connect Button") {
      selectedItem = {
        id: uuidv4(),
        type: "connectButton",
        config: {
          switchValueOne: false,
          switchValueTwo: false,
        },
        font: {
          fontFamily: "",
        },
      };
    } else if (item === "Large Text") {
      selectedItem = {
        id: uuidv4(),
        type: "largeText",
        text: "Large Text",
        textColor: "",
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Small Text") {
      selectedItem = {
        id: uuidv4(),
        type: "smallText",
        text: "Small Text",
        textColor: "",
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Image") {
      selectedItem = { id: uuidv4(), type: "image", image: "" };
    } else if (item === "Dynamic Contract Data") {
      selectedItem = {
        id: uuidv4(),
        type: "dynamicContractData",
        dynamicContractDataValues: [
          // {
          //   id: uuidv4(),
          //   type: "text",
          //   textValue: "Display data fetch by your contract",
          // },
        ],
        config: { configSwitch: false },
        font: {
          fontFamily: "default",
        },
      };
    } else if (item === "Social Media Icons") {
      selectedItem = {
        id: uuidv4(),
        type: "socialMediaIcons",
        icons: [],
        fontFamily: "default",
      };
    }
    dispatch(
      addBlockItem({
        sectionId: section.id,
        blockId: block.id,
        item: selectedItem,
      })
    );
    closeItemPicker();
  };

  const displayItemList = itemList.map((item, idx) => {
    return (
      <button key={idx} onClick={() => initiateAddBlockItem(item)}>
        {item}
      </button>
    );
  });

  return <div>{displayItemList}</div>;
}
