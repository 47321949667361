import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import firebase from "../../../../firebase/firebase";
import "./saveButton.css";

export default function SaveButton() {
  const db = firebase.firestore();
  const analytics = firebase.analytics();

  const [saving, setSaving] = useState(false);

  const reduxData = useSelector((state) => state.dapp);

  const initiateSave = async () => {
    setSaving(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const dappData = reduxData.dapp;

      const promOne = db
        .collection("users")
        .doc(user.uid)
        .collection("dapps")
        .doc(reduxData.dapp.id)
        .set({
          ...dappData,
        });

      const promTwo = db
        .collection("activeFrontends")
        .doc(dappData.dappName)
        .set({
          ...dappData,
        });

      Promise.all([promOne, promTwo])
        .then(async (res) => {
          analytics.logEvent("frontend_save");

          try {
            const docRefUserData = await db
              .collection("userData")
              .doc(user.uid);

            docRefUserData.update({
              dappSaved: firebase.firestore.FieldValue.increment(1),
            });
          } catch {
            console.log("Unable to save userData");
          }

          setSaving(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
  };
  return (
    <button className="saveButton" onClick={initiateSave}>
      {saving ? <Spinner animation="grow" /> : "save"}
    </button>
  );
}
