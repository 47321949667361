const googleFonts = [
  "Bangers",
  "Poppins",
  "Permanent Marker",
  "Caveat",
  "Acme",
  "Chakra Petch",
  "Press Start 2P",
  "Black Ops One",
  "Bebas Neue",
  "Sora",
  "Epilogue",
  "Hurricane",
  "Source Code Pro",
  "Inter",
  "Lora",
  "Roboto",
  "Raleway",
  "Red Hat Mono",
  "Inspiration",
  "Smokum",
  "Barlow",
  "Dela Gothic One",
  "Inconsolata",
  "Anton",
  "Pacifico",
  "Grape Nuts",
  "Rubik Moonrocks",
  "Anek Bangla",
  "Rubik Bubbles",
  "Rubik Wet Paint",
  "Exo 2",
  "Teko",
  "Shadows Into Light",
  "Secular One",
  "Indie Flower",
];

export default googleFonts;
