import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import DashboardDapp from "../../components/dappsDashboard/DashboardDapp";
import AppButton from "../../components/general/appButton/AppButton";
import Spinner from "../../components/spinnerComponent/SpinnerComponent";
import firebase from "../../firebase/firebase";
import "./dappsDashboard.css";

export default function DappsDashboard() {
  const db = firebase.firestore();
  const history = useHistory();
  const [dapps, setDapps] = useState([]);
  const [loadingDapps, setLoadingDapps] = useState(true);

  useEffect(async () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        //_________
        const querySnapshot = await db
          .collection("users")
          .doc(user.uid)
          .collection("dapps")
          .get();

        var contractTransferArr = [];
        querySnapshot.forEach((doc) => {
          contractTransferArr.push(doc.data());
        });

        setDapps(contractTransferArr);
        setLoadingDapps(false);
      } else {
        console.log("no user logged in");
        history.push("/Login");
      }
    });
  }, []);

  const redirectToPaymentForm = () => {};

  const displayDapps = dapps.map((dapp, idx) => {
    return <DashboardDapp key={idx} dapp={dapp} />;
  });

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        history.push("/Login");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  if (isMobile) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Please switch to a Desktop to continue using.
      </div>
    );
  }

  return (
    <div className="dappsDashboard">
      <AppButton label="Log out" func={logout} />

      <div className="dappsDashboard-heading">Dashboard</div>
      {loadingDapps ? (
        <div className="dappsDashboard-container-loading">
          <Spinner />
        </div>
      ) : (
        <div className="dappsDashboard-container"> {displayDapps}</div>
      )}
    </div>
  );
}
