import React from "react";
import "./section.css";
import Block from "./block/Block";

export default function Section({ section, inBuilder }) {
  const displayBlocks = section.blocks.map((block, idx) => {
    return (
      <Block key={idx} section={section} block={block} inBuilder={inBuilder} />
    );
  });
  return (
    <div
      className="section"
      style={{ backgroundColor: section.backgroundColor }}
    >
      {displayBlocks}
    </div>
  );
}
