import React from "react";
import { useHistory } from "react-router-dom";
import "./redirectText.css";

export default function RedirectText({ text, redirectLink }) {
  const history = useHistory();

  const redirect = () => {
    history.push(redirectLink);
  };

  return (
    <div className="redirectText" onClick={redirect}>
      {text}
    </div>
  );
}
