import firebase from "../firebase/firebase";
import ethereum_address from "ethereum-address";

const handleConnectContract = (
  e,
  dappId,
  dappName,
  frontendStructure,
  contractAddress,
  contractAbi,
  testContract,
  changingContract,
  afterCallFunc
) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (!dappName || !contractAddress || !contractAbi) {
      errorMessage = "Missing required fields";
      afterCallFunc("error", errorMessage);
      return;
    }

    if (!ethereum_address.isAddress(contractAddress)) {
      errorMessage = "Invalid contract address";
      afterCallFunc("error", errorMessage);
      return;
    }

    if (!testContract) {
      try {
        JSON.parse(contractAbi);
      } catch (err) {
        errorMessage = "Invalid contract abi";
        afterCallFunc("error", errorMessage);
        return;
      }
    }

    const db = firebase.firestore();
    const analytics = firebase.analytics();

    var errorMessage = "";

    if (!changingContract) {
      const querySnapshot = await db
        .collection("users")
        .doc(user.uid)
        .collection("dapps")
        .get();

      if (!querySnapshot.empty) {
        errorMessage =
          "It's only possible to have one dapp per account. You can always change the contract in your existing dapp";
        afterCallFunc("error", errorMessage);
        return;
      }
    }

    const promOne = db
      .collection("activeFrontends")
      .doc(dappName)
      .set({
        id: dappId,
        dappName: dappName,
        walletConnected: false,
        frontendStructure: frontendStructure,
        contracts: [
          {
            address: contractAddress,
            abi: testContract ? contractAbi : JSON.parse(contractAbi),
          },
        ],
        images: [],
        config: { windowClick: false },
      });

    const promTwo = db
      .collection("users")
      .doc(user.uid)
      .collection("dapps")
      .doc(dappId)
      .set({
        id: dappId,
        dappName: dappName,
        frontendStructure: frontendStructure,
        contracts: [
          {
            address: contractAddress,
            abi: testContract ? contractAbi : JSON.parse(contractAbi),
          },
        ],
        images: [],
        config: { windowClick: false },
      });

    Promise.all([promOne, promTwo])
      .then(async (res) => {
        analytics.logEvent("contract_connection");

        try {
          const docRefUserData = await db.collection("userData").doc(user.uid);

          if (!testContract) {
            docRefUserData.update({
              originalContractConnections:
                firebase.firestore.FieldValue.increment(1),
              dappName: dappName,
            });
          } else {
            docRefUserData.update({
              testContractConnections:
                firebase.firestore.FieldValue.increment(1),
              dappName: dappName,
            });
          }
        } catch {
          console.log("Unable to save userData");
        }

        afterCallFunc("success", "");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        afterCallFunc("error", errorCode);
      });
  });
};

export default handleConnectContract;
