import React from "react";
import "./configButtonWithIcon.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

export default function ConfigButtonWithIcon({ label, Icon }) {
  const history = useHistory();

  return (
    <button
      className="configButtonWithIcon"
      onClick={() => history.push("/DappsDashboard")}
    >
      <IoMdArrowRoundBack />
      {label}
    </button>
  );
}
