import React from "react";
import { useHistory } from "react-router-dom";
import "./dashboardDapp.css";
import DashboardDappButton from "./dashboardDappButton/DashboardDappButton";
import "./dashboardDappButton/dashboardDappButton.css";
import { config } from "../../constants";
import firebase from "../../firebase/firebase";

export default function DashboardDapp({ dapp }) {
  const history = useHistory();
  const db = firebase.firestore();

  const analytics = firebase.analytics();

  const saveUserData = (dappUrl) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        const docRefUserData = await db.collection("userData").doc(user.uid);

        docRefUserData.update({
          dappLaunched: firebase.firestore.FieldValue.increment(1),
          dappUrl: dappUrl,
        });
      } catch {
        console.log("Unable to save userData");
      }
    });
  };

  return (
    <div className="dashboardDapp">
      <div className="dapp-dappName"> {dapp.dappName}</div>
      <div className="dapp-contractsContainer">
        Contract:
        {dapp.contracts.map((contract, idx) => {
          return <div key={idx}>{contract.address}</div>;
        })}
      </div>
      <button
        className="dashboardDappButton-edit"
        onClick={() => {
          analytics.logEvent("dappBuilder_launch");
          history.push({
            pathname: "/DappsDashboard/DappBuilder",
            state: { dapp: dapp },
          });
        }}
      >
        Edit
      </button>

      <button
        className="dashboardDappButton"
        onClick={() => {
          analytics.logEvent("frontend_launch");
          saveUserData(`${config.url}/${dapp.dappName}`);
          window.open(`${config.url}/${dapp.dappName}`);
        }}
      >
        Launch
      </button>

      <button
        className="dashboardDappButton"
        onClick={() =>
          history.push({
            pathname: "/ConnectContract",
            state: { dapp: dapp },
          })
        }
      >
        Change Contract
      </button>
    </div>
  );
}
