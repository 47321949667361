import React, { useEffect, useState } from "react";
import ConfigHeader from "../configHeader/ConfigHeader";
import {
  setReorderedBlocks,
  toggleSectionConfigActive,
} from "../../../../../../redux/dapp";
import { useSelector, useDispatch } from "react-redux";
import AddCircle from "../../../../../icons/addCircle/AddCircle";
import { addBlock } from "../../../../../../redux/dapp";
import { v4 as uuidv4 } from "uuid";
import ColorPicker from "../colorPicker/ColorPicker";
import { changeSectionBackgroundColor } from "../../../../../../redux/dapp";
import ConfigBubble from "../configBubble/ConfigBubble";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";
import Form from "react-bootstrap/Form";
import DragNdrop from "../dragNdrop/DragNdrop";

export default function SectionConfig({ section }) {
  const dispatch = useDispatch();

  const [sectionBackgroundColor, setSectionBackgroundColor] = useState("");

  const displayBlocks = section.blocks.map((block, idx) => {
    return (
      <ConfigBubble
        key={idx}
        name={`Block ${idx + 1}`}
        actionFunc={toggleSectionConfigActive}
        actionFuncParams={{
          sectionId: section.id,
          selectedBlockConfig: block.id,
        }}
      />
    );
  });

  return (
    <>
      <ColorPicker
        colorLabel="Background Color"
        color={section.backgroundColor}
        changeColorFunc={changeSectionBackgroundColor}
        changeColorFuncParams={{ sectionId: section.id, color: "" }}
      />

      {section.blocks.length < 2 && (
        <ConfigStandardButton
          label="Add block"
          func={addBlock}
          reduxFunc={true}
          funcParams={{
            sectionId: section.id,
            block: {
              id: uuidv4(),
              type: "block",
              backgroundColor: "#25272a",
              config: {
                configOpen: true,
                configActive: true,
                selectedConfigItem: {},
              },
              items: [],
            },
          }}
        />
      )}

      <DragNdrop
        list={section.blocks}
        onDragFunc={setReorderedBlocks}
        onDragFuncParams={{
          sectionId: section.id,
        }}
        configBubbleFunc={toggleSectionConfigActive}
        configBubbleFuncParams={{
          sectionId: section.id,
        }}
      />
    </>
  );
}
