import React from "react";
import { useDispatch } from "react-redux";
import "./configStandardButton.css";

export default function ConfigStandardButton({
  label,
  func,
  reduxFunc,
  funcParams,
  clearInputFunc,
}) {
  const dispatch = useDispatch();

  return (
    <button
      className="configStandardButton"
      onClick={() => {
        reduxFunc ? dispatch(func(funcParams)) : func(funcParams);
        if (clearInputFunc) {
          clearInputFunc();
        }
      }}
    >
      {label}
    </button>
  );
}
