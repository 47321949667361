import React, { useEffect, useState } from "react";
import { changeItemDynamicContractDataValueReturnValue } from "../../../../../../../../redux/dapp";
import Web3 from "web3";
import Font from "react-font";
import { useDispatch, useSelector } from "react-redux";
import "./dynamicContractDataItem.css";

export default function DynamicContractDataItem({ section, block, item }) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.dapp);

  useEffect(() => {
    try {
      const viewFuncsValuesArr = [];

      item.dynamicContractDataValues.forEach(async (value) => {
        if (value.type === "viewFunc") {
          viewFuncsValuesArr.push(value);
        }
      });

      viewFuncsValuesArr.forEach(async (viewFuncsValue) => {
        const web3 = new Web3(window.ethereum);
        const abi = JSON.parse(
          JSON.stringify(viewFuncsValue.viewFunc.contract.abi)
        );

        const contractInstance = new web3.eth.Contract(
          abi,
          viewFuncsValue.viewFunc.contract.address
        );

        //! Add support for view funcs with parameters
        const paramsArr = [];
        //!

        const responseValue = await contractInstance.methods[
          viewFuncsValue.viewFunc.name
        ]().call();

        console.log("responseValue:", responseValue);

        dispatch(
          changeItemDynamicContractDataValueReturnValue({
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            dynamicContractDataValueId: viewFuncsValue.id,
            textValue: responseValue,
          })
        );
      });
    } catch (err) {
      console.log("Error:", err);
    }
  }, [item.config.configSwitch]);

  const displayData = item.dynamicContractDataValues.map((value, idx) => {
    return <div key={idx}>{value.textValue}</div>;
  });

  return (
    <div
      className="dynamicContractDataItem"
      style={{ color: section.backgroundColor }}
    >
      <Font family={reduxData.dapp.frontendStructure.font.fontFamily}>
        {displayData}
      </Font>
    </div>
  );
}
