import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Font from "react-font";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import selectedFunc from "../../../../../../../../helpers/selectedFunc";
import {
  changeItemSelectedFuncInputValue2,
  clearItemSelectedFuncInputValues,
  updateDynamicContractDataItems,
  updateItemDynamicContractData,
} from "../../../../../../../../redux/dapp";
import "../item.css";
import ItemResponseMessage from "../itemsComponents/ItemResponseMessage";
import "./buttonItem.css";

export default function ButtonItem({ section, block, item }) {
  const reduxData = useSelector((state) => state.dapp);

  const dispatch = useDispatch();

  const [contract, setContract] = useState(null);
  const [showingResponseMessage, setShowingResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [inputEmpty, setInputEmpty] = useState(false);
  const [buttonClassName, setButtonClassName] = useState("buttonItem-button");

  const [hoverActive, setHoverActive] = useState(false);

  const displaySelectedFuncInputs = item.selectedFunc.inputs.map(
    (input, idx) => {
      return (
        <Font
          key={idx}
          family={reduxData.dapp.frontendStructure.font.fontFamily}
        >
          <input
            style={{
              fontFamily: reduxData.dapp.frontendStructure.font.fontFamily,
            }}
            type={input.type.includes("uint") ? "number" : "text"}
            min={0}
            value={input.value}
            className="buttonItem-input"
            placeholder={
              input.placeholder.charAt(0).toUpperCase() +
              input.placeholder.slice(1)
            }
            onChange={(e) => {
              dispatch(
                changeItemSelectedFuncInputValue2({
                  sectionId: section.id,
                  blockId: block.id,
                  itemId: item.id,
                  inputId: input.id,
                  textValue: e.target.value,
                })
              );
            }}
          />
        </Font>
      );
    }
  );

  useEffect(() => {
    item.selectedFunc.inputs.map((input) => {
      if (!input.value) {
        setInputEmpty(true);
      } else {
        setInputEmpty(false);
      }
    });
  }, [item.selectedFunc.inputs]);

  useEffect(() => {
    if (item.selectedFunc.inputs.length !== 0) {
      setButtonClassName("buttonItem-button");
    } else {
      setButtonClassName("buttonItem-button-noInputs");
    }
  }, []);

  const initiateFuncCall = () => {
    if (item.selectedFunc.name === "Select Function") {
      return;
    }

    setLoading(true);

    if (item.selectedFunc.inputs.length !== 0) {
      setButtonClassName("buttonItem-button-loading");
    } else {
      setButtonClassName("buttonItem-button-noInputs-loading");
    }

    const web3 = new Web3(window.ethereum);
    const abi = JSON.parse(JSON.stringify(item.selectedFunc.contract.abi));

    const contractInstance = new web3.eth.Contract(
      abi,
      item.selectedFunc.contract.address
    );

    selectedFunc(contractInstance, item.selectedFunc, afterFuncCall);
  };

  const afterFuncCall = (result, response) => {
    setLoading(false);
    if (item.selectedFunc.inputs.length !== 0) {
      setButtonClassName("buttonItem-button");
    } else {
      setButtonClassName("buttonItem-button-noInputs");
    }

    if (result === "success") {
      setResponseMessage(item.successMessage);
      setShowingResponseMessage(true);
      dispatch(
        clearItemSelectedFuncInputValues({
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        })
      );

      dispatch(updateDynamicContractDataItems());
    } else if (result === "error") {
      setResponseMessage(item.errorMessage);
      setShowingResponseMessage(true);
    }
  };

  if (item.selectedFunc.inputs.length === 0) {
    return (
      <>
        <button
          // onMouseEnter={style={{backgroundColor: ""}}}
          disabled={inputEmpty || loading}
          className={buttonClassName}
          style={{
            backgroundColor: item.buttonBackgroundColor,
            color: item.buttonTextColor,
          }}
          onClick={initiateFuncCall}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: item.buttonTextColor }}
            />
          ) : (
            <Font family={reduxData.dapp.frontendStructure.font.fontFamily}>
              {item.buttonLabel}
            </Font>
          )}
        </button>
        <ItemResponseMessage
          showing={showingResponseMessage}
          responseMessage={responseMessage}
        />
      </>
    );
  }

  return (
    <div className="buttonItem item">
      {displaySelectedFuncInputs}

      <button
        className={buttonClassName}
        style={{
          backgroundColor: item.buttonBackgroundColor,
          color: item.buttonTextColor,
        }}
        onMouseEnter={() => {
          setHoverActive(true);
        }}
        onMouseLeave={() => {
          setHoverActive(false);
        }}
        disabled={inputEmpty || loading || !reduxData.dapp.walletConnected}
        onClick={initiateFuncCall}
      >
        {loading ? (
          <Spinner animation="border" style={{ color: item.buttonTextColor }} />
        ) : (
          <Font family={reduxData.dapp.frontendStructure.font.fontFamily}>
            {item.buttonLabel}
          </Font>
        )}
      </button>
      <ItemResponseMessage
        showing={showingResponseMessage}
        responseMessage={responseMessage}
        section={section}
      />
    </div>
  );
}
