import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { toggleItemConfigSwitchValue } from "../../../../../../redux/dapp";
import Form from "react-bootstrap/Form";

import "./colorPicker.css";

export default function ColorPicker({
  block,
  section,
  item,
  colorLabel,
  color,
  changeColorFunc,
  changeColorFuncParams,
  switchValue,
  switchValueLabel,
}) {
  const reduxData = useSelector((state) => state.dapp);
  const dispatch = useDispatch();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [testValue, setTestValue] = useState("");

  const [defaultColorValue, setDefaultColorValue] = useState();

  useEffect(() => {
    setDefaultColorValue("#cc1e1e");
  }, []);

  const initiateToggleSwitchValue = () => {
    if (section) {
      dispatch(
        toggleItemConfigSwitchValue({
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          value: switchValueLabel,
        })
      );
    }
  };

  return (
    <div className="colorPicker">
      <div className="colorPicker-label">{colorLabel}</div>
      <Form.Control
        type="color"
        value={color}
        title="Choose your color"
        onChange={(e) => {
          changeColorFuncParams.color = e.target.value;
          dispatch(changeColorFunc(changeColorFuncParams));
        }}
      />
    </div>
  );
}
