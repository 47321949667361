import React from "react";
import "./configHeader.css";
import ChevronCircle from "../../../../../icons/chevronCircle/ChevronCircle";
import { toggleSectionConfigActive } from "../../../../../../redux/dapp";
import { useSelector, useDispatch } from "react-redux";
import BackChevron from "../../../../../icons/backChevron/BackChevron";

export default function ConfigHeader({
  section,
  configName,
  configOpenToggleAction,
  configOpenToggleActionParams,
  configActiveToggleAction,
  configActiveToggleActionParams,
  configOpen,
  configActive,
}) {
  const reduxData = useSelector((state) => state.dapp);
  return (
    <div className="configHeader">
      {!configActive && (
        <BackChevron
          configActiveToggleAction={configActiveToggleAction}
          configActiveToggleActionParams={configActiveToggleActionParams}
        />
      )}
      {configName}
      <ChevronCircle
        section={section}
        configOpenToggleAction={configOpenToggleAction}
        configOpenToggleActionParams={configOpenToggleActionParams}
        configOpen={configOpen}
      />
    </div>
  );
}
