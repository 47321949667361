import React from "react";
import {
  changeItemSelectedFuncInputPlaceholder,
  changeItemSelectedFuncInputValue,
  changeItemSelectedFuncInputValue2,
} from "../../../../../../redux/dapp";
import ConfigInput from "../configInput/ConfigInput";
import "./configItemInputs.css";

export default function ConfigItemInputs({ block, section, item }) {
  const displayInputs = item.selectedFunc.inputs.map((input, idx) => {
    return (
      <ConfigInput
        key={idx}
        label={`Placeholder ${idx + 1}`}
        type="text"
        value={input.placeholder}
        onChangeFunc={changeItemSelectedFuncInputPlaceholder}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          inputId: input.id,
        }}
      />
    );
  });
  return <div className="configItemInputs">{displayInputs}</div>;
}
