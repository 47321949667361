import firebase from "../../firebase/firebase";

const signUpUser = (
  e,
  email,
  discordUsername,
  passwordOne,
  passwordTwo,
  afterCallFunc
) => {
  e.preventDefault();
  const db = firebase.firestore();
  const analytics = firebase.analytics();

  var errorMessage = "";

  if (!email || !discordUsername || !passwordOne || !passwordTwo) {
    errorMessage = "Missing required fields";
    afterCallFunc("error", errorMessage);
    return;
  }

  if (passwordOne !== passwordTwo) {
    errorMessage = "Password must match";
    afterCallFunc("error", errorMessage);
    return;
  }

  firebase
    .auth()
    .createUserWithEmailAndPassword(email, passwordTwo)
    .then(async (userCredential) => {
      var user = userCredential.user;
      const docRefOne = await db
        .collection("users")
        .doc(user.uid)
        .collection("account")
        .doc("freeTrial");

      docRefOne.set({
        started: new Date(),
      });

      const docRefTwo = await db
        .collection("users")
        .doc(user.uid)
        .collection("account")
        .doc("userInfo");

      docRefTwo.set({
        discordUsername: discordUsername,
      });

      const docRefTree = await db.collection("userData").doc(user.uid);

      docRefTree.set({
        discordUsername: discordUsername,
        email: email,
        singUpDate: new Date(),
        testContractConnections: 0,
        originalContractConnections: 0,
        dappName: "",
        dappSaved: 0,
        // blocksCreated: 0,
        // itemsCreated: 0,
        dappLaunched: 0,
        dappUrl: "",
      });

      analytics.logEvent("sign_up", { discordUsername: discordUsername });

      afterCallFunc("success", "");
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message.substring(
        10,
        error.message.indexOf(".")
      );

      afterCallFunc("error", errorMessage);
    });
};

export default signUpUser;
