import React, { useState } from "react";
import Button from "../elements/Button";
import "./earlyAccessSignUpForm.css";
import firebase from "../../../firebase/firebase";

export default function EarlyAccessSignUpForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const db = firebase.firestore();

  const initiateSaveEmail = () => {
    setResultMessage("");
    if (email === "") {
      setResultMessage("Please provide a valid email address.");
      return;
    }

    db.collection("emails")
      .add({
        email: email,
      })
      .then((res) => {
        setEmail("");
        setResultMessage(
          "Success! You'll be getting an email with instructions on how to sign up."
        );
      })
      .catch((err) => {
        setResultMessage(
          "There was an error :(. Please try again later or contact our support team."
        );
      });
  };

  return (
    <div className="earlyAccessSignUpForm">
      <div className="earlyAccessSignUpForm-container">
        {/* <input
          className="emailInput"
          value={email}
          placeholder="Your email"
          onChange={(e) => setEmail(e.target.value)}
        /> */}
        <Button
          tag="a"
          color="primary"
          wideMobile
          onClick={() => window.open("https://discord.gg/V2TmH8BBPy")}
        >
          Get early access
        </Button>
      </div>

      <div className="resultMessage">{resultMessage}</div>
    </div>
  );
}
