import React, { useState } from "react";
import "./configOptionPicker.css";
import { useDispatch } from "react-redux";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";

export default function ConfigOptionPicker({
  buttonLabel,
  options,
  buttonFunc,
  buttonFuncParams,
}) {
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);

  const displayOptions = options.map((option, idx) => {
    return (
      <button
        key={idx}
        onClick={() => {
          buttonFuncParams.itemOption = option;
          dispatch(buttonFunc(buttonFuncParams));
          setShowOptions(false);
        }}
      >
        {option.name}
      </button>
    );
  });

  return (
    <div className="configOptionPicker">
      {showOptions && <div>{displayOptions}</div>}
      <ConfigStandardButton
        label={showOptions ? "hide" : buttonLabel}
        func={setShowOptions}
        funcParams={!showOptions}
      />
    </div>
  );
}
