import React from "react";
import { useDispatch } from "react-redux";
import "./configDeleteButton.css";

export default function ConfigDeleteButton({ actionFunc, actionFuncParams }) {
  const dispatch = useDispatch();

  return (
    <button
      className="configDeleteButton"
      onClick={() => {
        dispatch(actionFunc(actionFuncParams));
      }}
    >
      Delete
    </button>
  );
}
