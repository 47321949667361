import getContractInstance from "./getContractInstance";
import getReduxData from "./getReduxData";
import Web3 from "web3";

const selectedFunc = async (contractInstance, func, afterFuncCall) => {
  const web3 = new Web3(window.ethereum);
  const accounts = await web3.eth.getAccounts();

  var exit = false;
  var exitMessage = "";

  const paramsArr = [];
  func.inputs.forEach((input) => {
    if (input.value === "") {
      exitMessage = "Empty value";
      console.log("exit 1");
      exit = true;
    } else if (input.type.includes("uint") & isNaN(parseInt(input.value))) {
      exitMessage = "Value that has to be a number is not";
      exit = true;
    } else if (
      input.type.includes("string") &
      (typeof input.value !== "string")
    ) {
      exitMessage = "Value that has to be a string is not";

      exit = true;
    } else if (
      input.type.includes("address") & !web3.utils.isAddress(input.value)
    ) {
      //check address Format
      console.log("exit here");

      exit = true;
    }

    if (input.type.includes("uint") & !isNaN(parseInt(input.value))) {
      paramsArr.push(parseInt(input.value));
    } else {
      paramsArr.push(input.value);
    }
  });

  if (exit) {
    return exitMessage;
  }

  switch (func.stateMutability) {
    case "view":
      contractInstance.methods[func.name](...paramsArr)
        .call()
        .then((res) => {
          afterFuncCall("success", res);
        })
        .catch((err) => {
          console.log("err", err);
          afterFuncCall("error", err);
        });
      break;

    case "nonpayable":
      console.log("...paramsArr:", ...paramsArr);

      contractInstance.methods[func.name](...paramsArr)
        .send({ from: accounts[0] })
        .then((res) => {
          afterFuncCall("success", res);
        })
        .catch((err) => {
          console.log("err", err);
          afterFuncCall("error", err);
        });
      break;
    case "payable":
      contractInstance.methods[func.name](...paramsArr)
        .send({
          from: accounts[0],
          value: web3.utils.toWei(func.price, "ether"),
        })
        .then((res) => {
          afterFuncCall("success", res);
        })
        .catch((err) => {
          console.log("err", err);
          afterFuncCall("error", err);
        });
      break;
  }
};

export default selectedFunc;
