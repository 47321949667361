import React from "react";
import ConfigHeader from "./configHeader/ConfigHeader";
import "./configContainer.css";

export default function ConfigContainer({
  section,
  block,
  item,
  ConfigComponent,
  configName,
  configOpenToggleAction,
  configOpenToggleActionParams,
  configActiveToggleAction,
  configActiveToggleActionParams,
  configOpen,
  configActive,
}) {
  return (
    <div className="configContainer">
      <ConfigHeader
        section={section}
        configName={configName}
        configOpenToggleAction={configOpenToggleAction}
        configOpenToggleActionParams={configOpenToggleActionParams}
        configActiveToggleAction={configActiveToggleAction}
        configActiveToggleActionParams={configActiveToggleActionParams}
        configOpen={configOpen}
        configActive={configActive}
      />
      {configOpen && (
        <div className="configContent">
          <ConfigComponent section={section} block={block} item={item} />
        </div>
      )}
    </div>
  );
}
