import React from "react";
import { useDispatch } from "react-redux";
import "./configBubble.css";

export default function ConfigBubble({ name, actionFunc, actionFuncParams }) {
  const dispatch = useDispatch();

  return (
    <div
      className="configBubble"
      onClick={() => {
        dispatch(actionFunc(actionFuncParams));
      }}
    >
      {name}
    </div>
  );
}
