import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import googleFonts from "./googleFonts";
import Font from "react-font";
import { changeFrontendStructureFont } from "../../../../../../redux/dapp";
import "./configFontPicker.css";

export default function ConfigFontPicker({
  item,
  changeFontFamilyFunc,
  changeFontFamilyFuncParams,
}) {
  const reduxData = useSelector((state) => state.dapp);
  const [showingFonts, setShowingFonts] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShowingFonts(false);
  }, [reduxData.dapp.config.windowClick]);

  const displayGoogleFonts = googleFonts.map((font, idx) => {
    return (
      <button
        key={idx}
        onClick={() =>
          dispatch(
            changeFontFamilyFunc({
              ...changeFontFamilyFuncParams,
              fontFamily: font,
            })
          )
        }
      >
        <Font family={font}>{font}</Font>
      </button>
    );
  });

  return (
    <div>
      <label>Font:</label>

      <button
        className="config-fontPickerButton"
        onClick={() => setShowingFonts(!showingFonts)}
      >
        {/* <Font className="font" family={item.font.fontFamily}> */}
        {item.font.fontFamily}
        {/* </Font> */}
      </button>

      {showingFonts && (
        <div className="fontPicker-fonts">{displayGoogleFonts}</div>
      )}
    </div>
  );
}
