import firebase from "../firebase/firebase";
import { v4 as uuidv4 } from "uuid";

const saveImages = (dapp, dappId, uploadedImages, imagesUploaded) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    const db = firebase.firestore();

    var storageRef = firebase.storage().ref();

    const imagesArr = Array.from(uploadedImages);
    var promises = [];
    var imagesIds = [];

    imagesArr.forEach((image) => {
      const imageId = uuidv4();
      imagesIds.push(imageId);
      const imageRef = storageRef.child(`${user.uid}/images/${imageId}`);
      promises.push(imageRef.put(image));
    });

    promises.push(
      db
        .collection("users")
        .doc(user.uid)
        .collection("dapps")
        .doc(dappId)
        .update({
          ...dapp,
          images: firebase.firestore.FieldValue.arrayUnion(...imagesIds),
        })
    );

    Promise.all(promises)
      .then((res) => {
        imagesUploaded(imagesIds);
      })
      .catch((err) => {
        console.log("err", err);
      });
  });
};

export default saveImages;
