import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DbCanvas from "../../components/dappBuilder/dbContainer/dbCanvas/DbCanvas";
import firebase from "../../firebase/firebase";
import { populateDapp } from "../../redux/dapp";

export default function DapposFrontend() {
  const db = firebase.firestore();
  const dispatch = useDispatch();

  useEffect(async () => {
    const url = window.location.href;
    const dappName = url.slice(url.lastIndexOf("/") + 1);
    console.log("dappName :", dappName);
    const docRef = db.collection("activeFrontends").doc(dappName);

    const result = await docRef.get();

    dispatch(populateDapp(result.data()));
  }, []);

  return <DbCanvas />;
}
