import React, { useEffect, useRef } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import DappsDashboard from "./pages/dappsDashboard/DappsDashboard";
import PreConnectContract from "./pages/preConnectContract/PreConnectContract";
import SignUp from "./pages/signUp/signUp";
import "./App.css";
import DappBuilder from "./pages/dappsDashboard/dappBuilder/DappBuilder";
import ConnectContract from "./pages/connectContract/ConnectContract";
import DapposFrontend from "./pages/dapposFrontend/DapposFrontend";
import ReactGA from "react-ga";
import ScrollReveal from "./landingPage/utils/ScrollReveal";
import LayoutDefault from "./landingPage/layouts/LayoutDefault";
import AppRoute from "./landingPage/utils/AppRoute";
import Home from "./landingPage/views/Home";
import Login from "./pages/login/Login";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

function App() {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <Route exact path="/SignUp">
            <SignUp />
          </Route>
          <Route exact path="/ForgotPassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/Login">
            <Login />
          </Route>
          <Route exact path="/PreConnectContract">
            <PreConnectContract />
          </Route>
          <Route exact path="/ConnectContract">
            <ConnectContract />
          </Route>
          <Route exact path="/DappsDashboard">
            <DappsDashboard />
          </Route>
          <Route exact path="/DappSDashboard/DappBuilder">
            <DappBuilder />
          </Route>
          <Route path="/:id">
            <DapposFrontend />
          </Route>
        </Switch>
      )}
    />
  );
}

export default App;
