import React from "react";
import "./dbTopBar.css";
import SaveButton from "./saveButton/SaveButton";

export default function DbTopBar() {
  return (
    <div className="dbTopBar">
      <SaveButton />
    </div>
  );
}
