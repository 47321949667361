import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import ConfigBubble from "../configBubble/ConfigBubble";

export default function DragNdrop({
  list,
  onDragFunc,
  onDragFuncParams,
  configBubbleFunc,
  configBubbleFuncParams,
}) {
  const dispatch = useDispatch();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    dispatch(onDragFunc({ ...onDragFuncParams, reorderedList: reorderedList }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            className="list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ConfigBubble
                      key={index}
                      name={item.type}
                      actionFunc={configBubbleFunc}
                      actionFuncParams={
                        item.type === "block"
                          ? {
                              ...configBubbleFuncParams,
                              selectedBlockConfig: item.id,
                            }
                          : {
                              ...configBubbleFuncParams,
                              item: item,
                            }
                      }
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
