import React from "react";
import "./formButton.css";
import Spinner from "react-bootstrap/Spinner";

export default function FormButton({
  label,
  func,
  funcParams,
  afterCallFunc,
  setStateLoading,
  loading,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      className="formButton"
      onClick={(e) => {
        setStateLoading(true);
        func(e, ...funcParams, afterCallFunc);
      }}
    >
      {loading ? <Spinner animation="border" /> : label}
    </button>
  );
}
