import firebase from "../firebase/firebase";

const getImages = (images, afterGetImages) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    const db = firebase.firestore();

    var storage = firebase.storage();
    var storageRef = firebase.storage().ref();

    var promises = [];
    images.forEach(async (imageId) => {
      const path = `${user.uid}/images/${imageId}`;
      promises.push(storageRef.child(path).getDownloadURL());
    });

    Promise.all(promises)
      .then((res) => {
        afterGetImages(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  });
};

export default getImages;
