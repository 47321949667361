import React, { useEffect, useState } from "react";
import Font from "react-font";
import { useSelector } from "react-redux";
import Web3 from "web3";
import "../item.css";
import "./connectButtonItem.css";
import { useDispatch } from "react-redux";
import { toggleWalletConnected } from "../../../../../../../../redux/dapp";

export default function ConnectButtonItem() {
  const reduxData = useSelector((state) => state.dapp);

  const [metamaskConnected, setMetamaskConnected] = useState(false);

  const dispatch = useDispatch();

  useEffect(async () => {
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    } else {
      return;
    }
    const metamaskAccounts = await web3.eth.getAccounts();

    if (metamaskAccounts.length > 0) {
      setMetamaskConnected(true);
      dispatch(toggleWalletConnected({ value: true }));
    } else {
      dispatch(toggleWalletConnected({ value: false }));
    }
  }, []);

  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          setMetamaskConnected(true);
          dispatch(toggleWalletConnected({ value: true }));
        })
        .catch((err) => {
          console.log("Unable to connect with Metamask");
        });
    }
  };

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length === 0) {
        setMetamaskConnected(false);
        dispatch(toggleWalletConnected({ value: false }));
      }
    });
  }

  return (
    <button
      className="connectButtonItem item"
      style={{
        backgroundColor:
          reduxData.dapp.frontendStructure.connectButton.backgroundColor,
        color: reduxData.dapp.frontendStructure.connectButton.textColor,
      }}
      onClick={() => connectMetamask()}
    >
      <Font family={reduxData.dapp.frontendStructure.font.fontFamily}>
        {metamaskConnected ? "Connected" : "Connect"}
      </Font>
    </button>
  );
}
