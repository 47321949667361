import React, { useEffect, useState } from "react";
import Font from "react-font";
import { useDispatch, useSelector } from "react-redux";
import { changeFrontendStructureFont } from "../../../../../../redux/dapp";
import ConfigStandardButton from "../configStandardButton/ConfigStandardButton";
import "./fontPicker.css";
import googleFonts from "./googleFonts";

export default function FontPicker() {
  const reduxData = useSelector((state) => state.dapp);
  const [showingFonts, setShowingFonts] = useState(false);

  useEffect(() => {
    setShowingFonts(false);
  }, [reduxData.dapp.config.windowClick]);

  const dispatch = useDispatch();
  const displayGoogleFonts = googleFonts.map((font, idx) => {
    return (
      <button
        key={idx}
        onClick={() => dispatch(changeFrontendStructureFont({ font: font }))}
      >
        <Font family={font}>{font}</Font>
      </button>
    );
  });

  return (
    <div>
      <ConfigStandardButton
        label="Fonts"
        func={setShowingFonts}
        reduxFunc={false}
        funcParams={!showingFonts}
      />

      {showingFonts && (
        <div className="fontPicker-fonts">{displayGoogleFonts}</div>
      )}
    </div>
  );
}
