import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeItemSelectedFunc } from "../../../../../../../redux/dapp";
import { v4 as uuidv4 } from "uuid";
import "./configContractFuncs.css";
import ConfigStandardButton from "../../configStandardButton/ConfigStandardButton";
import ConfigContractFuncButton from "../configContractFuncButton/ConfigContractFuncButton";
import ConfigContractFuncsContainer from "../ConfigContractFuncsContainer";

export default function ConfigContractFuncs({
  block,
  section,
  item,
  contract,
}) {
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  const displayContractFuncs = contract.abi.map((func, idx) => {
    if (func.type === "function") {
      const inputsArr = [];
      func.inputs.forEach((input) => {
        inputsArr.push({
          ...input,
          id: uuidv4(),
          placeholder: input.name,
          value: "",
        });
      });

      const selectedFunc = {
        name: func.name,
        stateMutability: func.stateMutability,
        type: func.type,
        price: 0,
        inputs: inputsArr,
        outputs: func.outputs,
        contract: contract,
      };

      if (func.stateMutability === undefined) {
        selectedFunc.stateMutability = "undefined";
      }

      return (
        <ConfigContractFuncButton
          key={idx}
          section={section}
          block={block}
          item={item}
          func={func}
          onClickFunc={changeItemSelectedFunc}
          onClickFuncParams={{
            sectionId: section.id,
            blockId: block.id,
            itemId: item.id,
            func: selectedFunc,
          }}
        />
      );
    } else {
      console.log("function type: ", func.type);
    }
  });

  return <ConfigContractFuncsContainer funcs={displayContractFuncs} />;
}
