import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButtonItemButtonBackgroundColor,
  changeButtonItemButtonLabel,
  changeButtonItemButtonTextColor,
  changeButtonItemSuccessMessage,
  changeButtonItemErrorMessage,
  deleteItem,
} from "../../../../../../../../../redux/dapp";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import ConfigInput from "../../../../configInput/ConfigInput";

import ConfigDeleteButton from "../../../../configDeleteButton/ConfigDeleteButton";
import ConfigItemInputs from "../../../../configItemInputs/ConfigItemInputs";
import ConfigContractFuncs from "../../../../configContractFuncsContainer/configContractFuncs/ConfigContractFuncs";
import ConfigItemFuncPrice from "../../../../configItemFuncPrice/ConfigItemFuncPrice";

export default function ButtonItemConfig({ block, section, item }) {
  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state.dapp);

  return (
    <>
      <ColorPicker
        section={section}
        block={block}
        item={item}
        colorLabel="Background Color"
        color={item.buttonBackgroundColor}
        changeColorFunc={changeButtonItemButtonBackgroundColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
        switchValueLabel="1"
        switchValue={item.config.switchValueOne}
      />
      <ColorPicker
        section={section}
        block={block}
        item={item}
        colorLabel="Text Color"
        color={item.buttonTextColor}
        changeColorFunc={changeButtonItemButtonTextColor}
        changeColorFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          color: "",
        }}
        switchValueLabel="2"
        switchValue={item.config.switchValueTwo}
      />
      <ConfigInput
        label={"button label"}
        type="text"
        value={item.buttonLabel}
        onChangeFunc={changeButtonItemButtonLabel}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />
      <ConfigContractFuncs
        section={section}
        block={block}
        item={item}
        contract={reduxData.dapp.contracts[0]}
      />

      {item.selectedFunc.stateMutability === "payable" && (
        <ConfigItemFuncPrice section={section} block={block} item={item} />
      )}
      <ConfigItemInputs section={section} block={block} item={item} />
      <ConfigInput
        label={"Success Message"}
        type="text"
        value={item.successMessage}
        onChangeFunc={changeButtonItemSuccessMessage}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />
      <ConfigInput
        label={"Error Message"}
        type="text"
        value={item.errorMessage}
        onChangeFunc={changeButtonItemErrorMessage}
        onChangeFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
          textValue: "",
        }}
      />

      <ConfigDeleteButton
        actionFunc={deleteItem}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
    </>
  );
}
