import firebase from "../../firebase/firebase";

const resetPassword = (e, email, afterCallFunc) => {
  e.preventDefault();

  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      const responseMessage = `A link to reset your password has been emailed to ${email}. Make sure to check your spam folder.`;
      afterCallFunc(responseMessage, "success");
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;

      afterCallFunc(errorCode, "error");
    });
};

export default resetPassword;
