import React from "react";
import "./appButton.css";

export default function AppButton({ label, func }) {
  return (
    <button className="appButton logOutButton-dashboard" onClick={() => func()}>
      {label}
    </button>
  );
}
