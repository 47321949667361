import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const dappSlice = createSlice({
  name: "dapp",
  initialState: {
    loadingDapp: true,
    dapp: {
      id: "",
      dappName: "",
      walletConnected: false,
      frontendStructure: {
        sections: [
          {
            id: uuidv4(),
            backgroundColor: "",
            config: {
              configOpen: true,
              configActive: true,
              selectedBlockConfig: "",
            },
            blocks: [
              {
                id: uuidv4(),
                backgroundColor: "",
                config: {
                  configOpen: true,
                  configActive: true,
                  selectedConfigItem: {},
                },
                items: [
                  {
                    id: uuidv4(),
                    type: "button",
                    name: "Button",
                    font: {
                      fontFamily: "",
                    },
                    buttonLabel: "",
                    buttonBackgroundColor: "",
                    buttonTextColor: "",
                    selectedFunc: {
                      name: "Select Function",
                      stateMutability: "nonpayable",
                      type: "function",
                      price: 0,
                      inputs: [],
                      outputs: [],
                      contract: { address: "", abi: [{}] },
                    },
                    successMessage: "",
                    errorMessage: "",
                    config: {
                      switchValueOne: false,
                      switchValueTwo: false,
                      configSwitch: false,
                    },
                    dynamicContractDataValues: [
                      {
                        id: uuidv4(),
                        type: "viewFunc",
                        viewFunc: { func: {}, contract: {}, returnValue: "" },
                      },
                    ],
                    icons: [{ url: "" }],
                  },
                ],
              },
            ],
          },
        ],
        font: {
          fontFamily: "Roboto",
          config: { configOpen: true, configActive: true },
        },
        connectButton: { backgroundColor: "", textColor: "" },
      },
      contracts: [],
      images: [],
      config: { windowClick: false },
    },
  },
  reducers: {
    populateDapp: (state, action) => {
      return {
        ...state,
        dapp: action.payload,
        loadingDapp: false,
      };
    },

    toggleWalletConnected: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          walletConnected: action.payload.value,
        },
      };
    },

    toggleWindowClick: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          config: {
            ...state.dapp.config,
            windowClick: !state.dapp.config.windowClick,
          },
        },
      };
    },

    changeFrontendStructureFont: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            font: {
              ...state.dapp.frontendStructure.font,
              fontFamily: action.payload.font,
            },
          },
        },
      };
    },
    toggleFontConfigOpen: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            font: {
              ...state.dapp.frontendStructure.font,
              config: {
                ...state.dapp.frontendStructure.font.config,
                configOpen:
                  !state.dapp.frontendStructure.font.config.configOpen,
              },
            },
          },
        },
      };
    },

    //Configs__________________________
    toggleSectionConfigOpen: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  config: {
                    ...section.config,
                    configOpen: !section.config.configOpen,
                  },
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    toggleSectionConfigActive: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  config: {
                    ...section.config,
                    configActive: !section.config.configActive,
                    selectedBlockConfig: action.payload.selectedBlockConfig,
                  },
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    //________________________________

    //Section__________________________
    addBlock: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: [...section.blocks, action.payload.block],
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeSectionBackgroundColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  backgroundColor: action.payload.color,
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    setReorderedBlocks: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: action.payload.reorderedList,
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    //________________________________

    //Connect Button__________________________
    changeConnectButtonBackgroundColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            connectButton: {
              ...state.dapp.frontendStructure.connectButton,
              backgroundColor: action.payload.color,
            },
          },
        },
      };
    },
    changeConnectButtonTextColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            connectButton: {
              ...state.dapp.frontendStructure.connectButton,
              textColor: action.payload.color,
            },
          },
        },
      };
    },

    //________________________________

    //Block_________________________
    toggleBlockConfigActive: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        config: {
                          ...block.config,
                          configActive: !block.config.configActive,
                          selectedConfigItem: action.payload.item,
                        },
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    addBlockItem: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: [...block.items, action.payload.item],
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeBlockBackgroundColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        backgroundColor: action.payload.color,
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    deleteBlock: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: [
                    ...section.blocks.filter(
                      (block) => block.id !== action.payload.blockId
                    ),
                  ],
                  config: {
                    ...section.config,
                    configActive: true,
                  },
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    setReorderedBlockItems: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: action.payload.reorderedList,
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    //________________________________

    //Item_________________________
    addItemIcon: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              icons: [...item.icons, action.payload.itemOption],
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeIconUrl: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              icons: item.icons.map((icon) => {
                                if (icon.id === action.payload.iconId) {
                                  return {
                                    ...icon,
                                    url: action.payload.textValue,
                                  };
                                } else {
                                  return icon;
                                }
                              }),
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemText: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              text: action.payload.textValue,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemFontFamily: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              font: {
                                ...item.font,
                                fontFamily: action.payload.fontFamily,
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemImage: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              image: action.payload.image,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemTextColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              textColor: action.payload.color,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemSelectedFunc: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: action.payload.func,
                              buttonLabel: action.payload.func.name,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemSelectedFuncPrice: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: {
                                ...item.selectedFunc,
                                price: action.payload.textValue,
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemSelectedFuncInputValue2: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: {
                                ...item.selectedFunc,
                                inputs: item.selectedFunc.inputs.map(
                                  (input) => {
                                    if (input.id === action.payload.inputId) {
                                      return {
                                        ...input,
                                        value: action.payload.textValue,
                                      };
                                    } else {
                                      return input;
                                    }
                                  }
                                ),
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemSelectedFuncInputPlaceholder: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: {
                                ...item.selectedFunc,
                                inputs: item.selectedFunc.inputs.map(
                                  (input) => {
                                    if (input.id === action.payload.inputId) {
                                      return {
                                        ...input,
                                        placeholder: action.payload.textValue,
                                      };
                                    } else {
                                      return input;
                                    }
                                  }
                                ),
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    deleteItem: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: [
                          ...block.items.filter(
                            (item) => item.id !== action.payload.itemId
                          ),
                        ],
                        config: {
                          ...block.config,
                          configActive: true,
                        },
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemSelectedFuncInputValue: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: {
                                ...item.selectedFunc,
                                inputs: item.selectedFunc.inputs.map(
                                  (input) => {
                                    if (input.id === action.payload.inputId) {
                                      return {
                                        ...input,
                                        value: action.payload.textValue,
                                      };
                                    } else {
                                      return input;
                                    }
                                  }
                                ),
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    toggleItemConfigSwitchValue: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            if (action.payload.value === "1") {
                              return {
                                ...item,
                                config: {
                                  ...item.config,
                                  switchValueTwo: !item.config.switchValueTwo,
                                },
                              };
                            } else if (action.payload.value === "2") {
                              return {
                                ...item,
                                config: {
                                  ...item.config,
                                  switchValueOne: !item.config.switchValueOne,
                                },
                              };
                            }
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    clearItemSelectedFuncInputValues: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              selectedFunc: {
                                ...item.selectedFunc,
                                inputs: item.selectedFunc.inputs.map(
                                  (input) => {
                                    return {
                                      ...input,
                                      value: "",
                                    };
                                  }
                                ),
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    //Dynamic Contract Data Item______________________________

    addItemDynamicContractDataValue: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              dynamicContractDataValues: [
                                ...item.dynamicContractDataValues,
                                action.payload.value,
                              ],
                              config: {
                                ...item.config,
                                configSwitch: !item.config.configSwitch,
                              },
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    changeItemDynamicContractDataValueReturnValue: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              dynamicContractDataValues:
                                item.dynamicContractDataValues.map(
                                  (dynamicContractDataValue) => {
                                    if (
                                      dynamicContractDataValue.id ===
                                      action.payload.dynamicContractDataValueId
                                    ) {
                                      return {
                                        ...dynamicContractDataValue,
                                        textValue: action.payload.textValue,
                                      };
                                    } else {
                                      return dynamicContractDataValue;
                                    }
                                  }
                                ),
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },

    updateDynamicContractDataItems: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              return {
                ...section,
                blocks: section.blocks.map((block) => {
                  return {
                    ...block,
                    items: block.items.map((item) => {
                      if (item.type === "dynamicContractData") {
                        return {
                          ...item,
                          config: {
                            ...item.config,
                            configSwitch: !item.config.configSwitch,
                          },
                        };
                      } else {
                        return item;
                      }
                    }),
                  };
                }),
              };
            }),
          },
        },
      };
    },

    //ButtonItem_________________________

    changeButtonItemButtonLabel: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              buttonLabel: action.payload.textValue,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeItemIconsBackgroundColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              iconsColor: action.payload.color,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeButtonItemButtonBackgroundColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              buttonBackgroundColor: action.payload.color,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeButtonItemButtonTextColor: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              buttonTextColor: action.payload.color,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeButtonItemSuccessMessage: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              successMessage: action.payload.textValue,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    changeButtonItemErrorMessage: (state, action) => {
      return {
        ...state,
        dapp: {
          ...state.dapp,
          frontendStructure: {
            ...state.dapp.frontendStructure,
            sections: state.dapp.frontendStructure.sections.map((section) => {
              if (section.id === action.payload.sectionId) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (block.id === action.payload.blockId) {
                      return {
                        ...block,
                        items: block.items.map((item) => {
                          if (item.id === action.payload.itemId) {
                            return {
                              ...item,
                              errorMessage: action.payload.textValue,
                            };
                          } else {
                            return item;
                          }
                        }),
                      };
                    } else {
                      return block;
                    }
                  }),
                };
              } else {
                return section;
              }
            }),
          },
        },
      };
    },
    //________________________________
    //________________________________
  },
});

export const {
  populateDapp,
  toggleWalletConnected,
  toggleSectionConfigOpen,
  toggleSectionConfigActive,
  changeConnectButtonBackgroundColor,
  changeConnectButtonTextColor,
  addBlock,
  addBlockItem,
  toggleBlockConfigActive,
  changeSectionBackgroundColor,
  changeBlockBackgroundColor,
  changeButtonItemButtonLabel,
  changeButtonItemButtonBackgroundColor,
  changeButtonItemButtonTextColor,
  changeButtonItemSuccessMessage,
  changeButtonItemErrorMessage,
  changeItemTextColor,
  changeItemText,
  changeItemSelectedFunc,
  changeItemSelectedFuncInputValue,
  deleteBlock,
  deleteItem,
  changeItemImage,
  changeFrontendStructureFont,
  toggleFontConfigOpen,
  toggleWindowClick,
  toggleItemConfigSwitchValue,
  changeItemSelectedFuncInputValue2,
  clearItemSelectedFuncInputValues,
  addItemDynamicContractDataValue,
  changeItemSelectedFuncPrice,
  changeItemDynamicContractDataValueReturnValue,
  addItemIcon,
  changeIconUrl,
  changeItemIconsBackgroundColor,
  setReorderedBlockItems,
  setReorderedBlocks,
  changeItemFontFamily,
  changeItemSelectedFuncInputPlaceholder,
  updateDynamicContractDataItems,
} = dappSlice.actions;
export default dappSlice.reducer;
