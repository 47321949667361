import firebase from "../../firebase/firebase";

const loginUser = (e, email, password, afterCallFunc) => {
  e.preventDefault();
  const db = firebase.firestore();

  var errorMessage;

  if (!email || !password) {
    errorMessage = "Missing required fields";
    afterCallFunc("error", errorMessage);
    return;
  }

  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (userCredential) => {
      var user = userCredential.user;

      const querySnapshot = await db
        .collection("users")
        .doc(user.uid)
        .collection("dapps")
        .get();

      afterCallFunc("success", "", querySnapshot.empty);
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message.substring(
        10,
        error.message.indexOf(".")
      );

      afterCallFunc("error", errorMessage, null);
    });
};

export default loginUser;
