import React, { useEffect } from "react";
import ColorPicker from "../../../../colorPicker/ColorPicker";
import { useSelector, useDispatch } from "react-redux";
import {
  changeConnectButtonBackgroundColor,
  changeConnectButtonTextColor,
  deleteItem,
} from "../../../../../../../../../redux/dapp";
import ConfigDeleteButton from "../../../../configDeleteButton/ConfigDeleteButton";

export default function ConnectButtonItemConfig({ block, section, item }) {
  const reduxData = useSelector((state) => state.dapp);

  useEffect(() => {
    console.log("reduxData:", reduxData);
  }, []);

  return (
    <div>
      <ColorPicker
        colorLabel="Background Color"
        color={reduxData.dapp.frontendStructure.connectButton.backgroundColor}
        changeColorFunc={changeConnectButtonBackgroundColor}
        changeColorFuncParams={{
          color: "",
        }}
      />
      <ColorPicker
        colorLabel="Text Color"
        color={reduxData.dapp.frontendStructure.connectButton.textColor}
        changeColorFunc={changeConnectButtonTextColor}
        changeColorFuncParams={{
          color: "",
        }}
      />

      <ConfigDeleteButton
        actionFunc={deleteItem}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
    </div>
  );
}
