import React from "react";
import { useDispatch } from "react-redux";
import "./configContractFuncButton.css";

export default function ConfigContractFuncButton({
  block,
  section,
  item,
  func,
  onClickFunc,
  onClickFuncParams,
}) {
  const dispatch = useDispatch();

  return (
    <button
      className="configContractFuncButton"
      onClick={() => dispatch(onClickFunc(onClickFuncParams))}
    >
      {func.name}
    </button>
  );
}
