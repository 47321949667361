import React, { useState } from "react";
import "./formTextArea.css";

export default function FormTextArea({ placeHolder, value, setStateValue }) {
  const [textAreaValue, setTextAreaValue] = useState("");

  return (
    <textarea
      className="formTextArea"
      placeholder={placeHolder}
      value={value}
      onChange={(e) => setStateValue(e.target.value)}
    >
      FormTextArea
    </textarea>
  );
}
