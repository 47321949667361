import classNames from "classnames";
import React from "react";
import mainLandingPageImageOne from "../../../images/Dappos example UI 7.png";
import mainLandingPageImageTwo from "../../../images/ShadowTokenImage2.png";
import { SectionProps } from "../../utils/SectionProps";
import EarlyAccessSignUpForm from "../earlyAccessSignUpForm/EarlyAccessSignUpForm";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Create Dapp UIs with no {""}
              <span className="text-color-primary">code</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Mint pages, exchanges, defi games for your Smart Contracts in
                minutes!
              </p>
              <EarlyAccessSignUpForm />
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className="has-shadow"
              src={mainLandingPageImageOne}
              alt="Hero"
              width={600}
              height={10}
            />
            {/* <Image
              className="has-shadow"
              src={mainLandingPageImageTwo}
              alt="Hero"
              width={500}
              height={504}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
