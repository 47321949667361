import React from "react";
import "./mobileViewMessage.css";

export default function MobileViewMessage() {
  return (
    <div className="mobileViewMessage">
      Please switch to a Desktop to continue using.
    </div>
  );
}
