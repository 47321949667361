import React, { useState, useEffect } from "react";
import Font from "react-font";
import { BsSunFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import "./itemResponseMessage.css";

export default function ItemResponseMessage({
  showing,
  responseMessage,
  section,
}) {
  const reduxData = useSelector((state) => state.dapp);

  const [color, setColor] = useState("");

  useEffect(() => {
    if (section) {
      setColor(section.backgroundColor);
    }
  }, [section]);

  return (
    <div className="itemResponseMessage" style={{ color: color }}>
      {showing && (
        <Font family={reduxData.dapp.frontendStructure.font.fontFamily}>
          {responseMessage}
        </Font>
      )}
    </div>
  );
}
