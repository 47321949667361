import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  addItemDynamicContractDataValue,
  deleteItem,
} from "../../../../../../../../../redux/dapp";
import ConfigContractViewFuncs from "../../../../configContractFuncsContainer/configContractViewFuncs/ConfigContractViewFuncs";
import ConfigDeleteButton from "../../../../configDeleteButton/ConfigDeleteButton";
import ConfigInputTextCreator from "../../../../configInputTextCreator/ConfigInputTextCreator";
import "./dynamicContractDataItemConfig.css";

export default function DynamicContractDataItemConfig({
  block,
  section,
  item,
  contract,
}) {
  const reduxData = useSelector((state) => state.dapp);

  const displayDynamicContractDataValues = item.dynamicContractDataValues.map(
    (value) => {
      if (value.type === "text") {
        return value.textValue;
      } else if (value.type === "viewFunc") {
        return value.viewFunc.name;
      }
    }
  );

  return (
    <div className="dynamicContractDataItemConfig">
      <ConfigContractViewFuncs
        section={section}
        block={block}
        item={item}
        contract={reduxData.dapp.contracts[0]}
      />

      <ConfigInputTextCreator
        section={section}
        block={block}
        item={item}
        actionFunc={addItemDynamicContractDataValue}
      />
      <div>{displayDynamicContractDataValues}</div>

      <ConfigDeleteButton
        actionFunc={deleteItem}
        actionFuncParams={{
          sectionId: section.id,
          blockId: block.id,
          itemId: item.id,
        }}
      />
    </div>
  );
}
